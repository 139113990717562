<template>
  <c-tab
    type="tabcard"
    align="left"
    :tabItems.sync="tabItems"
    :inlineLabel="true"
    v-model="tab"
    @tabClick="tabClick"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
        :param="popupParam"
        :preStartupCheck.sync="preStartupCheck"
        :imprTabInfo.sync="imprTabInfo"
        :research.sync="research"
        @closePopup="closePopup"
      />
    </template>
  </c-tab>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'pre-startup-review-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',
        psrCheckStatusCd: '',
        isSearch: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'base',
      tabItems: [
        { name: 'base', icon: 'foundation', label: '가동전점검 계획/결과 정보', component: () => import(`${'./preStartupReviewInfo.vue'}`) },
        { name: 'impr', icon: 'account_tree', label: 'Punch list', component: () => import(`${'@/pages/common/ibm/mobileImprTab.vue'}`) },
      ],
      preStartupCheck: {
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        plantCd: null,  // 사업장코드
        checkName: '',  // 점검명
        checkPurpose: '',  // 점검 목적
        psrEquipmentStatusCd: null,  // 설비 상태
        checkDeptCd: null,  // 점검부서
        sopMocId: '',  // MOC 일련번호
        checkCancarrierId: '',  // 점검 책임자
        checkResultTotal: '',  // 점검결과 종합
        psrCheckStatusCd: '',  // 진행 단계
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        deleteEquipments: [],
      },
      editable: true,
      research: {
        impr: '',
        item: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 45) + 'px';
    },
    imprTabInfo() {
      return {
        // Mobile용 Start
        // customCol에 대한 card title
        requestContentsCols: ['level'],
        // readOnly용으로 card-detail 내용이 들어감
        customCols: [
          {
            key: uid(), // v-for key
            label: '점검결과', // grid에 대한 하나의 column label
            name: 'checkResult' // grid에 대한 하나의 column name
          }
        ],
        // Mobile용 End
        key: this.popupParam.sopPrestartupCheckId, // 
        ibmTaskTypeCd: 'ITT0000030',
        ibmTaskUnderTypeCd: 'ITTU000045',
        requestContents: this.preStartupCheck.checkName,
        disabled: this.preStartupCheck.psrCheckStatusCd === 'PCSC000005',
        isOld: Boolean(this.popupParam.sopPrestartupCheckId),
        gridItem: {
          listUrl: selectConfig.sop.psr.checkItemImprove.list.url,
          param: {
            sopPrestartupCheckId: this.popupParam.sopPrestartupCheckId,
          },
          title: '점검결과 별 개선사항 목록', // 점검 결과 별 개선사항 목록
          merge: [
          ],
          columns: [
            {
              name: 'checkItemName',
              field: 'checkItemName',
              // 점검항목
              label: '점검항목',
              sortable: false,
              child: [
                {
                  name: 'level',
                  field: 'level',
                  // 대분류/중분류/소분류
                  label: '대분류/중분류/소분류',
                  align: 'left',
                  sortable: false,
                },
              ]
            },
            {
              name: 'judgmentResultFlagName',
              field: 'judgmentResultFlagName',
              // 적합여부
              label: '적합<br>여부',
              align: 'center',
              style: 'width:55px',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: '항목외 개선 목록', // 항목외 개선 목록
        },
      }
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    closePopup() {
      this.$emit('closePopup')
    },
    tabClick() {
      this.$set(this.research, 'impr', uid())
    }
  }
};
</script>
